// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthRegisterMutationRequest, AuthRegisterMutationResponse } from "../models/AuthRegister";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthRegisterClient = typeof client<AuthRegisterMutationResponse, never, AuthRegisterMutationRequest>;
type AuthRegister = {
    data: AuthRegisterMutationResponse;
    error: never;
    request: AuthRegisterMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthRegisterMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthRegisterClient>[0]>;
        return: Awaited<ReturnType<AuthRegisterClient>>;
    };
};
/**
 * @summary 注册第一步：输入注册信息并发送验证码
 * @link /api/v5/auth/email/register
 */
export function useAuthRegister(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthRegister["response"], AuthRegister["error"], {
        data?: AuthRegister["request"];
    }>;
    client?: AuthRegister["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthRegister["data"], AuthRegister["error"], AuthRegister["request"]>({
                method: "post",
                url: `/api/v5/auth/email/register`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}