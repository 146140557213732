import { useAuthVerifySpaceRegistrationCodeAndCreateSpace } from '@/api'
import { KBFlex, KBInput, KBTypography } from '@/components/atoms'
import { KBIntlRenderReactDom } from '@/components/organisms/KBIntlRenderReactDom'
import { motion } from 'framer-motion'
import { ArrowLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './index.module.css'

const { Text } = KBTypography

const RegisterVerification: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { email } = location.state
  const navigate = useNavigate()

  const { mutate: codeVerify } =
    useAuthVerifySpaceRegistrationCodeAndCreateSpace()

  const verifyCode = (code: string) => {
    codeVerify(
      {
        data: {
          code,
          email: email,
        },
      },
      {
        onSuccess: (res) => {
          navigate('/register/password', {
            state: {
              userToken: res.resetPasswordToken,
            },
          })
        },
      }
    )
  }
  return (
    <KBFlex
      gap={32}
      vertical
      align="center"
      justify="center"
      className="tw-h-full tw-w-full"
    >
      <div className="tw-w-[476px] tw-text-center">
        <Text className="tw-mb-2 tw-block  tw-text-3xl tw-font-bold">
          {t('register.verification')}
        </Text>
        <Text className="tw-block tw-text-base" type="secondary">
          <KBIntlRenderReactDom
            intlKey={'register.verificationTips'}
            placeholders={{
              email: (
                <Text className="tw-text-base tw-font-bold" type="secondary">
                  {email}
                </Text>
              ),
            }}
          />
        </Text>
      </div>
      <div className={styles.codeInput}>
        <KBInput.OTP
          onChange={(value) => {
            verifyCode(value)
          }}
          length={6}
          size="large"
        />
      </div>

      <KBFlex
        gap={8}
        onClick={() => {
          navigate('/register/form')
        }}
        className="tw-cursor-pointer"
      >
        <motion.div
          className="tw-pt-[3px]"
          whileHover={{
            x: -10,
          }}
        >
          <ArrowLeft size={18} />
        </motion.div>
        <span>{t('goBack')}</span>
      </KBFlex>
    </KBFlex>
  )
}

export default RegisterVerification
