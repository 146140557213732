import { LoginResponseDto, useAuthLoginByTeamsToken } from '@/api'
import { localeAtom, themeAtom } from '@/store'
import { SUPPORTED_LOCALES } from '@/utils/localization/supported_locales'
import { isH5 } from '@/utils/userEnvironment'
import { app, authentication } from '@microsoft/teams-js'
import { useAtom } from 'jotai'

interface UseTeamsProps {
  onLoginSuccess: (data: LoginResponseDto) => Promise<void>
  // @ts-ignore
  onLoginError: (error) => void
}

/**
 * 初始化teams并登录
 * 1. 初始化teams
 * 2. 获取teams token
 * 3. 登录
 * 4. 登录成功后，调用onLoginSuccess
 * 5. 登录失败后，调用onLoginError
 */
const useTeams = (props: UseTeamsProps) => {
  const { onLoginSuccess, onLoginError } = props
  const [teamInitLoading, setTeamInitLoading] = useState(false)
  const [teamAuthLoading, setTeamAuthLoading] = useState(false)
  const { mutate: loginByTeamsToken } = useAuthLoginByTeamsToken()
  const [theme, setTheme] = useAtom(themeAtom)
  const [locale, setLocale] = useAtom(localeAtom)
  const appInfoRef = useRef({
    theme: 'default',
    locale: 'en-us',
  })

  const setWebTheme = (theme: string) => {
    theme === 'default' ? setTheme('light') : setTheme('dark')
  }

  const setWebLocale = (teamsLocale: string) => {
    const parts = teamsLocale.split('-')
    if (parts.length > 1) {
      const teamsLanguage = `${parts[0]}-${parts[1].toUpperCase()}`
      const selectedLocale =
        SUPPORTED_LOCALES.find((lang) => lang.value === teamsLanguage)?.value ||
        'en-US'

      if (locale !== selectedLocale) {
        setLocale(selectedLocale)
      }
    }
  }

  const navigateToH5 = (token: string) => {
    const h5Url = import.meta.env.VITE_H5_URL
    const searchParams = new URLSearchParams({
      ...appInfoRef.current,
      token,
    }).toString()

    if (location.pathname === '/user/reservations/records') {
      location.href = `${h5Url}/#/teams/reservations?${searchParams}`
    } else {
      location.href = `${h5Url}/#/teams/map?${searchParams}`
    }
  }

  const initTeams = useCallback(async () => {
    setTeamInitLoading(true)

    try {
      await app.initialize()

      const context = await app.getContext()
      console.log('teams app context', context)

      setWebTheme(context.app.theme)
      setWebLocale(context.app.locale)
      appInfoRef.current = {
        theme: context.app.theme,
        locale: context.app.locale,
      }

      app.notifySuccess()
    } catch (e) {
      console.log('初始化失败', e)
      onLoginError(e)
      app.notifyFailure({
        reason: app.FailedReason.Other,
        // @ts-ignore
        message: `Teams App initialization failed: ${e?.message}`,
      })
    }

    setTeamInitLoading(false)
  }, [])

  const loginWithTeams = async () => {
    setTeamAuthLoading(true)

    try {
      const auth_token = await authentication.getAuthToken()

      loginByTeamsToken(
        {
          // @ts-ignore
          data: {
            token: auth_token,
          },
        },
        {
          onSuccess: (data: LoginResponseDto) => {
            setTeamAuthLoading(false)

            if (isH5) {
              navigateToH5(data.token)
            } else {
              onLoginSuccess(data)
            }
          },
          onError: (error) => {
            setTeamAuthLoading(false)
            onLoginError(error)
          },
        }
      )
    } catch (e) {
      console.log('loginWithTeams - error', e)
      setTeamAuthLoading(false)
      onLoginError(e)
    }
  }

  const loading = teamInitLoading || teamAuthLoading

  return {
    initTeams,
    loginWithTeams,
    loading,
  }
}

export default useTeams
