import { KBSelect, KBSelectProps } from '@/components/atoms/KBSelect'
import { locationAtom } from '@/store'
import { cn } from '@/utils/tailwind.helper'
import dayjs, {
  Dayjs,
  findClosestMinute,
  findClosestTimeWithStep,
  formatStorage,
  formatTime,
} from '@wox/dayjs'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

export interface KBTimeSelectPickerProps extends KBSelectProps {
  value?: Dayjs
  onChange?: (value: Dayjs) => void
  /**
   * @description 间隔时间
   */
  step?: number
  /**
   * @description 时间格式.一般不需要传入
   */
  format?: 'HH:mm' | 'LT'
  /**
   * @description 是否禁用某个时间。如果useCurrentTime是false，会启用当前时间的前一个step的时间
   * @example 当前时间是 16:52, useCurrentTime是false, step=30分钟，16:30会显示且作为enabled
   * @param date 时间
   * @returns 是否禁用
   */
  disabledTime?: (date: Dayjs) => boolean | undefined
  /**
   * @description 是否使用当前时间. 如果true，且当前时间不是整数时，也会作为option插入。
   * @example 当前时间是 16:52, 间隔30分钟，16:52会显示为一个选项。
   */
  useCurrentTime?: boolean
  useDefaultTime?: boolean // 外部传入默认时间
  /**
   * 是否显示23:59:59
   */
  includesEndOfDay?: boolean
}

export interface KBTimeSelectPickerOptionItem {
  label: string
  value: string
  disabled?: boolean
}

export type KBTimeSelectPickerOptions = KBTimeSelectPickerOptionItem[]

function getTimeOptions(
  step: number,
  value: Dayjs,
  useCurrentTime?: boolean,
  disabledTime?: (date: Dayjs) => boolean | undefined,
  includesEndOfDay?: boolean
): KBTimeSelectPickerOptions {
  const startTime = findClosestTimeWithStep(dayjs(value).startOf('day'), step)
  const endTime = dayjs(value).add(1, 'day').startOf('day')
  let stepTime = startTime

  const timeOptions: KBTimeSelectPickerOptions = []
  do {
    timeOptions.push({
      label: formatTime(stepTime),
      value: stepTime.format(),
      disabled: disabledTime?.(
        // 如果采用当前时间，则不需要加step，否则加step用来enable前一个时段
        useCurrentTime ? stepTime : stepTime.add(step, 'minute')
      ),
    })
    stepTime = stepTime.add(step, 'minute')
  } while (stepTime.isSameOrBefore(endTime))

  if (includesEndOfDay) {
    const endOfDay = startTime.endOf('day')
    timeOptions.push({
      label: formatTime(endOfDay),
      value: endOfDay.format(),
      disabled: disabledTime?.(endOfDay),
    })
    timeOptions.sort((a, b) => dayjs(a.value).diff(dayjs(b.value)))
  }

  // Add value if it's not one of the options
  if (useCurrentTime) {
    const valueOption = {
      label: formatTime(value),
      value: value.second(0).format(),
      disabled: false, // disabledTime?.(value),
    }
    // console.log('valueOption', valueOption)

    if (!timeOptions.some((option) => option.value === valueOption.value)) {
      timeOptions.push(valueOption)
      timeOptions.sort((a, b) => dayjs(a.value).diff(dayjs(b.value)))
    }
  }

  return timeOptions
}

/**
 * 选择时间，按step显示
 * @param props
 * @returns
 */
export function KBTimeSelectPicker(props: KBTimeSelectPickerProps) {
  const { t } = useTranslation()
  const {
    step: _step,
    onChange,
    value = dayjs(), // value in dayjs
    placeholder = t('common.selectTime'),
    className,
    disabledTime,
    useCurrentTime = false,
    useDefaultTime = false,
    includesEndOfDay = false,
    ...otherProps
  } = props

  // const minutes = findClosestMinute(value, step) // value.diff(value?.startOf('day'), 'minute') //
  const [inValue, setInValue] = useState<string | undefined>(undefined)

  const [currentLocation] = useAtom(locationAtom)

  const step = useMemo(() => {
    return _step || currentLocation?.time_interval || 30
  }, [_step, currentLocation])

  // console.log('useCurrentTime', useCurrentTime)
  useEffect(() => {
    const startTime = value
    const seconds = findClosestMinute(startTime, step) * 60
    if (useDefaultTime) {
      setInValue(
        dayjs(
          startTime.startOf('day').add(seconds, 'second').toISOString()
        ).format()
      )
    } else {
      if (!useCurrentTime) {
        const currentTime = dayjs(
          startTime.startOf('day').add(seconds, 'second').toISOString()
        )
        setInValue(currentTime.format())
      } else {
        setInValue(startTime.second(0).format())
      }
    }
  }, [value, step, useCurrentTime])

  const handleChange = (value: string) => {
    // const startTime = time.startOf('day').add(value, 'second')
    // setTime(startTime)
    // setInValue(value)
    setInValue(value)
    onChange?.(dayjs(value))
  }

  const lastProps = useRef<KBTimeSelectPickerProps>({})
  const lastOptions = useRef<KBTimeSelectPickerOptions>()

  const options = useMemo(
    () => {
      const {
        step: oldStep,
        value: oldValue,
        useCurrentTime: oldUseCurrentTime,
        disabledTime: oldDisabledTime,
        includesEndOfDay: oldIncludesEndOfDay,
      } = lastProps.current

      // 因为react是浅比较，对dayjs对象和disabledTime无法正确识别变化，这里手动判断下减少render次数
      if (
        step !== oldStep ||
        value.format() !== oldValue?.format() ||
        useCurrentTime !== oldUseCurrentTime ||
        disabledTime !== oldDisabledTime ||
        includesEndOfDay !== oldIncludesEndOfDay
      ) {
        lastProps.current = {
          step,
          value: value,
          useCurrentTime,
          disabledTime,
          includesEndOfDay,
        }
        const newOptions = getTimeOptions(
          step,
          value || dayjs(),
          useCurrentTime,
          disabledTime,
          includesEndOfDay
        )
        lastOptions.current = newOptions
        return newOptions
        // 对比新旧options是否有变化决定是否render
        // const hasChange = newOptions.some((newOption, index) => {
        //   const oldOption = lastOptions.current?.[index]
        //   if (!oldOption) {
        //     return true
        //   } else {
        //     const {
        //       label: oldLabel,
        //       value: oldValue,
        //       disabled: oldDisabled,
        //     } = oldOption
        //     const { label, value, disabled } = newOption
        //     return (
        //       label !== oldLabel ||
        //       value !== oldValue ||
        //       disabled !== oldDisabled
        //     )
        //   }
        // })

        // if (hasChange) {
        //   console.log('重新render')
        //   lastOptions.current = newOptions
        //   return newOptions
        // } else {
        //   return lastOptions.current
        // }
      } else {
        return lastOptions.current
      }
    },
    // removed disabledTime from memo because it would cause re-render and performance becomes abysmally slow
    // also the function should not be memoized because it would not change between renders
    [step, value, useCurrentTime, disabledTime, includesEndOfDay]
  )

  // console.log('options', options)

  const is24HourFormat = formatStorage.get()?.timeFormat === 24
  // console.log('inValue', inValue)
  return (
    <KBSelect
      className={cn(
        is24HourFormat ? 'tw-w-124' : 'tw-w-128',
        'tw-min-w-[98px]',
        className
      )}
      value={inValue}
      placeholder={placeholder}
      onChange={handleChange}
      options={options}
      {...otherProps}
    />
  )
}
