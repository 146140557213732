import { KBButton, KBFlex, KBResult, KBText } from '@/components/atoms'
import { useTranslation } from 'react-i18next'

const NoRegister = () => {
  const { t } = useTranslation()
  const contactUs = () => {
    window.open('https://woxday.com/trial', '_blank')
  }
  return (
    <KBFlex
      justify="center"
      align="center"
      className="tw-h-screen tw-w-screen tw-bg-[var(--wox-color-bg-layout)]"
    >
      <KBResult
        icon={
          <img
            src="/billing/noData.svg"
            alt={t('common.noReservationResource')}
          />
        }
        title={
          <KBText level={1} weight="bold">
            {t('noRegister.title')}
          </KBText>
        }
        subTitle={t('noRegister.description')}
        extra={
          <KBButton type="primary" onClick={contactUs}>
            {t('common.contactUs')}
          </KBButton>
        }
      />
    </KBFlex>
  )
}

export default NoRegister
