// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthVerifySpaceRegistrationCodeAndCreateSpaceMutationRequest, AuthVerifySpaceRegistrationCodeAndCreateSpaceMutationResponse } from "../models/AuthVerifySpaceRegistrationCodeAndCreateSpace";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthVerifySpaceRegistrationCodeAndCreateSpaceClient = typeof client<AuthVerifySpaceRegistrationCodeAndCreateSpaceMutationResponse, never, AuthVerifySpaceRegistrationCodeAndCreateSpaceMutationRequest>;
type AuthVerifySpaceRegistrationCodeAndCreateSpace = {
    data: AuthVerifySpaceRegistrationCodeAndCreateSpaceMutationResponse;
    error: never;
    request: AuthVerifySpaceRegistrationCodeAndCreateSpaceMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthVerifySpaceRegistrationCodeAndCreateSpaceMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthVerifySpaceRegistrationCodeAndCreateSpaceClient>[0]>;
        return: Awaited<ReturnType<AuthVerifySpaceRegistrationCodeAndCreateSpaceClient>>;
    };
};
/**
 * @summary 注册第二步：验证邮箱验证码
 * @link /api/v5/auth/email/verify-space-registration-code
 */
export function useAuthVerifySpaceRegistrationCodeAndCreateSpace(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthVerifySpaceRegistrationCodeAndCreateSpace["response"], AuthVerifySpaceRegistrationCodeAndCreateSpace["error"], {
        data?: AuthVerifySpaceRegistrationCodeAndCreateSpace["request"];
    }>;
    client?: AuthVerifySpaceRegistrationCodeAndCreateSpace["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthVerifySpaceRegistrationCodeAndCreateSpace["data"], AuthVerifySpaceRegistrationCodeAndCreateSpace["error"], AuthVerifySpaceRegistrationCodeAndCreateSpace["request"]>({
                method: "post",
                url: `/api/v5/auth/email/verify-space-registration-code`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}