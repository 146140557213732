import { KBFlex, KBLayout } from '@/components/atoms'
import { KBLanguageDropdown } from '@/components/organisms'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import WoxIconLogo from '~icons/wox-icons/woxLogo.svg'
const FreeRegistrationLayout: React.FC = () => {
  const { t } = useTranslation()

  return (
    <KBLayout className="tw-bg-[var(--wox-color-fill-quaternary)]">
      <KBLayout.Header className="tw-bg-[var(--wox-color-fill-quaternary)] tw-text-right">
        <KBLanguageDropdown />
      </KBLayout.Header>
      <KBLayout.Content className="tw-flex tw-h-[calc(100vh-64px)] tw-items-center tw-justify-center tw-px-2.5">
        <div className="tw-flex tw-h-full tw-items-center tw-justify-center tw-bg-[var(--wox-color-fill-quaternary)]">
          <KBFlex
            vertical
            gap={32}
            align="center"
            className="tw-h-[620px] tw-w-[1024px]  "
          >
            <WoxIconLogo className="tw-flex-shrink-0 " />
            <div className="tw-w-full tw-flex-1 tw-rounded-lg tw-border tw-border-[var(--wox-color-border)] tw-bg-[var(--wox-color-bg-layout)] tw-shadow-[0px_2px_6px_-1px_rgba(0,0,0,0.08)]">
              <Outlet />
            </div>
          </KBFlex>
        </div>
      </KBLayout.Content>
    </KBLayout>
  )
}

export default FreeRegistrationLayout
