import {
  EmployeeWorkStatusEntity,
  ResourceReservationEntity,
  SimpleResourceEntity,
  useEmployeeWorkStatusFindMany,
  useResourceReservationIndex,
} from '@/api'
import {
  KBAvatar,
  KBButton,
  KBDivider,
  KBFlex,
  KBIcon,
  KBPopover,
  KBText,
  KBTypography,
} from '@/components/atoms'
import { KBUserAvatar } from '@/components/organisms'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { getResourceTypeIcon } from '@/pages/admin/resources/utils/iconUtils'
import { currentSpaceAtom } from '@/store'
import { getUserAvatar } from '@/utils/avatarUrl'
import { cn } from '@/utils/tailwind.helper'
import { isAdminClient, isUserClient } from '@/utils/userEnvironment'
import { PopoverProps, theme } from 'antd'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import WoxIconCompany from '~icons/wox-icons/company'
import WoxIconHome from '~icons/wox-icons/home'
import WoxIconRest from '~icons/wox-icons/rest'
const { Text } = KBTypography

export interface KBUserPopoverProps extends PopoverProps {
  user?: KBUserAvatar
  children: ReactNode | string
  toolTipContent?: React.ReactNode
  /**
   * 是否展示预定记录
   */
  showReservationRecord?: boolean
  /**
   * 点击预定记录回调
   */
  onClickResource?: (resource: SimpleResourceEntity) => void
}
export function KBUserPopover({
  user,
  children,
  toolTipContent,
  onClickResource,
  showReservationRecord = false,
  ...props
}: KBUserPopoverProps) {
  const { t } = useTranslation()
  const { useToken } = theme
  const { token } = useToken()
  const navigate = useNavigate()
  const currentLocationId = useCurrentLocationId()
  const [currentSpace] = useAtom(currentSpaceAtom)

  //  过滤只有全天预定且关联地图的资源？--与产品确认全部展示
  const { data: reservationsData } = useResourceReservationIndex(
    {
      host_id: user?.id,
      start_at: dayjs().startOf('day').format(),
      end_at: dayjs().endOf('day').format(),
      reservation_type: ['Reservation'],
    },
    {
      query: {
        enabled: showReservationRecord,
      },
    }
  )

  const reservations = reservationsData?.result as ResourceReservationEntity[]

  const goToUserUrl = isUserClient()
    ? `/user/users/${user?.id}`
    : currentLocationId
      ? `/admin/locations/${currentLocationId}/users/${user?.id}`
      : `/admin/users/${user?.id}`

  const { data } = useEmployeeWorkStatusFindMany({
    date: dayjs().format('YYYY-MM-DD'),
    user_id: user?.id,
  })

  const workStatuses = data?.result as EmployeeWorkStatusEntity[]

  const workStatus = workStatuses?.find((status) => status.user_id === user?.id)
  let background = ''
  switch (workStatus?.status) {
    case 'WorkOffice':
      background = `linear-gradient(90deg, #6350F0 0%, #4DCEFE 100%)`
      break
    case 'WorkHome':
      background = `linear-gradient(90deg, #FF8008 0%, #FFC837 100%)`
      break
    case 'Rest':
      background = `linear-gradient(90deg, #02AAB0 0%, #00CDAC 100%)`
      break
    default:
      background = `var(--wox-color-fill-quaternary)`
  }
  const content = (
    <KBFlex vertical>
      <KBFlex
        vertical
        className={`tw-min-w-[248px] ${
          showReservationRecord ? 'tw-p-4' : 'tw-p-1'
        }`}
      >
        <KBFlex
          gap={8}
          align={
            isUserClient() &&
            !currentSpace?.user_card_config?.display_fields?.length
              ? 'center'
              : 'start'
          }
        >
          <KBAvatar
            size={40}
            src={getUserAvatar(user)}
            className="tw-flex-shrink-0 tw-cursor-pointer"
            onClick={(e) => {
              e?.stopPropagation()
              navigate(goToUserUrl)
            }}
          />
          <KBFlex vertical gap={8}>
            <KBFlex
              vertical
              gap={2}
              onClick={(e) => {
                e.stopPropagation()
                navigate(goToUserUrl)
              }}
              className="tw-cursor-pointer"
            >
              <KBTypography.Text className="tw-font-medium">
                {user?.name}
              </KBTypography.Text>
              {(isAdminClient() ||
                currentSpace?.user_card_config?.display_fields?.includes(
                  'show_email'
                )) && (
                <KBTypography.Text className="tw-text-xs tw-text-[var(--wox-color-text-description)]">
                  {user?.email ? user?.email : t('common.noEmail')}
                </KBTypography.Text>
              )}
            </KBFlex>
            <KBFlex gap={6} align="center">
              {toolTipContent}
              {/* 办公状态未知显示灰色 */}
              {(isAdminClient() ||
                currentSpace?.user_card_config?.display_fields?.includes(
                  'show_location'
                )) && (
                <KBFlex
                  gap={4}
                  align="center"
                  className="tw-rounded-[44px] tw-px-2 tw-py-0.5"
                  style={{
                    background: `${background}`,
                  }}
                >
                  {(!workStatus?.status ||
                    workStatus?.status === 'Unknown') && (
                    <KBIcon name="circle-help" size={14} />
                  )}
                  {workStatus?.status === 'WorkOffice' && (
                    <WoxIconCompany className="tw-size-[14px]" />
                  )}
                  {workStatus?.status === 'WorkHome' && (
                    <WoxIconHome className="tw-size-[14px]" />
                  )}
                  {workStatus?.status === 'Rest' && (
                    <WoxIconRest className="tw-size-[14px]" />
                  )}
                  <KBTypography.Text className="tw-text-xs tw-text-white">
                    {(!workStatus?.status ||
                      workStatus?.status === 'Unknown') && (
                      <KBTypography.Text
                        type="secondary"
                        className="tw-text-xs"
                      >
                        {t('common.unknown')}
                      </KBTypography.Text>
                    )}
                    {workStatus?.status === 'WorkOffice' &&
                      workStatus?.location?.name}
                    {workStatus?.status === 'WorkHome' &&
                      t('workStatus.WorkHome')}
                    {workStatus?.status === 'Rest' && t('workStatus.Rest')}
                  </KBTypography.Text>
                </KBFlex>
              )}
              {(isAdminClient() ||
                currentSpace?.user_card_config?.display_fields?.includes(
                  'show_department'
                )) && (
                <KBFlex
                  gap={4}
                  align="center"
                  className="tw-rounded-[44px] tw-bg-[var(--wox-color-fill-quaternary)] tw-px-2 tw-py-0.5"
                >
                  <KBIcon name="network" size={12} />
                  <KBTypography.Text
                    type="secondary"
                    className="tw-text-xs tw-font-normal"
                  >
                    {user?.department?.name || '-'}
                  </KBTypography.Text>
                </KBFlex>
              )}
            </KBFlex>
          </KBFlex>
        </KBFlex>
      </KBFlex>
      {showReservationRecord && reservations?.length > 0 && (
        <KBDivider className="tw-my-0" />
      )}
      {showReservationRecord && (
        <KBFlex vertical gap={4} className="tw-px-4 tw-pb-4">
          {reservations?.length > 0 && (
            <>
              <div className="tw-pt-2">
                <KBText
                  level={3}
                  className="tw-text-[var(--wox-color-text-tertiary)]"
                >
                  {t('reservation.reservedResources')}
                </KBText>
              </div>
            </>
          )}
          {reservations?.slice(0, 3)?.map((reservation) => (
            <KBFlex
              gap={8}
              className={cn(
                'tw-cursor-pointer tw-rounded-md tw-px-3  tw-py-[10px] hover:tw-bg-[var(--wox-color-fill-quaternary)] '
              )}
              onClick={() => {
                reservation.resource && onClickResource?.(reservation.resource)
              }}
            >
              <img
                src={getResourceTypeIcon(
                  reservation?.resource?.resource_type?.icon
                )}
                style={{ width: '16px' }}
              />

              <Text className="tw-text-black-10 tw-text-xs">
                {reservation.resource?.name}
                {reservation.resource?.location?.name && (
                  <>
                    ,{'  '}
                    {reservation.resource?.location?.name}
                  </>
                )}
              </Text>
            </KBFlex>
          ))}
          {reservations?.length > 3 && (
            <KBFlex className="tw-mt-1">
              <KBButton
                className="tw-w-full"
                onClick={() => navigate(`/user/users/${user?.id}`)}
              >
                {t('reservation.seeMore')}
              </KBButton>
            </KBFlex>
          )}
        </KBFlex>
      )}
    </KBFlex>
  )

  return (
    <KBPopover
      content={content}
      arrow={false}
      overlayInnerStyle={{
        border: `1px solid ${token.colorBorderSecondary}`,
      }}
      align={{
        offset: [0, -12],
      }}
      {...props}
    >
      {children}
    </KBPopover>
  )
}
