// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceReservationIndexQueryResponse, ResourceReservationIndexQueryParams, ResourceReservationIndex401 } from "../models/ResourceReservationIndex";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceReservationIndexClient = typeof client<ResourceReservationIndexQueryResponse, ResourceReservationIndex401, never>;
type ResourceReservationIndex = {
    data: ResourceReservationIndexQueryResponse;
    error: ResourceReservationIndex401;
    request: never;
    pathParams: never;
    queryParams: ResourceReservationIndexQueryParams;
    headerParams: never;
    response: ResourceReservationIndexQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationIndexClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationIndexClient>>;
    };
};
export const resourceReservationIndexQueryKey = (params?: ResourceReservationIndex["queryParams"]) => ["api", "v5", "resource-reservations", ...(params ? [params] : [])] as const;
export type ResourceReservationIndexQueryKey = ReturnType<typeof resourceReservationIndexQueryKey>;
export function resourceReservationIndexQueryOptions(params?: ResourceReservationIndex["queryParams"], options: ResourceReservationIndex["client"]["parameters"] = {}) {
    const queryKey = resourceReservationIndexQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationIndex["data"], ResourceReservationIndex["error"]>({
                method: "get",
                url: `/api/v5/resource-reservations`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 预定日历预定查询接口
 * @link /api/v5/resource-reservations
 */
export function useResourceReservationIndex<TData = ResourceReservationIndex["response"], TQueryData = ResourceReservationIndex["response"], TQueryKey extends QueryKey = ResourceReservationIndexQueryKey>(params?: ResourceReservationIndex["queryParams"], options: {
    query?: Partial<QueryObserverOptions<ResourceReservationIndex["response"], ResourceReservationIndex["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceReservationIndex["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceReservationIndex["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationIndexQueryKey(params);
    const query = useQuery({
        ...resourceReservationIndexQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceReservationIndex["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceReservationIndexSuspenseQueryKey = (params?: ResourceReservationIndex["queryParams"]) => ["api", "v5", "resource-reservations", ...(params ? [params] : [])] as const;
export type ResourceReservationIndexSuspenseQueryKey = ReturnType<typeof resourceReservationIndexSuspenseQueryKey>;
export function resourceReservationIndexSuspenseQueryOptions(params?: ResourceReservationIndex["queryParams"], options: ResourceReservationIndex["client"]["parameters"] = {}) {
    const queryKey = resourceReservationIndexSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationIndex["data"], ResourceReservationIndex["error"]>({
                method: "get",
                url: `/api/v5/resource-reservations`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 预定日历预定查询接口
 * @link /api/v5/resource-reservations
 */
export function useResourceReservationIndexSuspense<TData = ResourceReservationIndex["response"], TQueryKey extends QueryKey = ResourceReservationIndexSuspenseQueryKey>(params?: ResourceReservationIndex["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceReservationIndex["response"], ResourceReservationIndex["error"], TData, TQueryKey>>;
    client?: ResourceReservationIndex["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceReservationIndex["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationIndexSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...resourceReservationIndexSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceReservationIndex["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}