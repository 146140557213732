/**
 * 获取用户环境、这里可以做一些环境的区分，如客户端环境h5/pc，当前是用户端或者是管理端
 */

/**
 * 是否是用户端
 */
export const isUserClient = () => {
  return window.location.pathname.startsWith('/user')
}

/**
 * 是否是管理端
 */
export const isAdminClient = () => {
  return window.location.pathname.startsWith('/admin')
}

/**
 * 是否是teams环境
 */
export const isTeams = () => {
  return window.sessionStorage.getItem('inTeams') === 'true'
}

/**
 * 是否是h5环境
 */
export const isH5 =
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    navigator.userAgent
  )
