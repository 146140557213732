import { useAuthRegister } from '@/api'
import {
  KBButton,
  KBCol,
  KBFlex,
  KBForm,
  KBInput,
  KBRow,
  KBText,
  KBTypography,
} from '@/components/atoms'
import { KBIntlRenderReactDom } from '@/components/organisms/KBIntlRenderReactDom'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const { Text } = KBTypography

const RegisterForm: React.FC = () => {
  const { t } = useTranslation()
  const [form] = KBForm.useForm()
  const navigate = useNavigate()
  const TERMS_AND_PRIVACY = {
    terms: `${import.meta.env.VITE_TERMS_URL}`,
    privacy: `${import.meta.env.VITE_PRIVACY_URL}`,
  }
  const { mutate: register, isPending } = useAuthRegister()

  const onSubmit = () => {
    form.validateFields().then((values) => {
      register(
        {
          data: {
            ...values,
          },
        },
        {
          onSuccess: () => {
            navigate('/register/verification', {
              state: {
                email: values.email,
              },
            })
          },
        }
      )
    })
  }
  return (
    <KBFlex gap={0} wrap={false} className="tw-h-full tw-w-full">
      <KBFlex
        align="center"
        justify="center"
        className="tw-w-[512px] tw-flex-shrink-0 tw-px-12"
      >
        <img
          src="/registerBg.png"
          width={416}
          className="tw-mx-0 tw-my-auto"
          title=""
        />
      </KBFlex>
      <div className="tw-box-border tw-h-full tw-flex-1 tw-border tw-border-y-0 tw-border-r-0 tw-border-solid tw-border-[var(--wox-color-border-secondary)] tw-px-12 tw-py-10">
        <Text className="tw-mb-2 tw-text-2xl tw-font-bold tw-text-gray-800">
          {t('register.startUsingWOX')}
        </Text>
        <Text className="tw-mb-8 tw-block tw-text-sm tw-text-gray-500">
          {t('register.description')}
        </Text>
        <KBForm layout="vertical" form={form}>
          <KBForm.Item
            label={t('register.companyEmail')}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
              },
            ]}
          >
            <KBInput />
          </KBForm.Item>
          <KBRow gutter={24}>
            <KBCol span={12}>
              <KBForm.Item
                label={t('common.firstName')}
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="firstName"
              >
                <KBInput />
              </KBForm.Item>
            </KBCol>
            <KBCol span={12}>
              <KBForm.Item
                label={t('common.lastName')}
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="lastName"
              >
                <KBInput />
              </KBForm.Item>
            </KBCol>
          </KBRow>
          <KBForm.Item
            label={t('register.companyName')}
            name="company"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <KBInput />
          </KBForm.Item>
          <KBForm.Item>
            <KBButton
              size="large"
              type="primary"
              className="tw-w-full"
              onClick={onSubmit}
              loading={isPending}
            >
              {t('register.gotoStart')}
            </KBButton>
          </KBForm.Item>

          <Trans
            className="tw-mb-[9px] tw-flex-shrink-0 tw-text-[sm]"
            i18nKey="register.loginText"
            components={{
              a: (
                <a
                  target="_blank"
                  href="/login"
                  rel="noopener noreferrer"
                  className="tw-text-[var(--wox-color-primary)]"
                />
              ),
            }}
          />

          <KBText type="secondary" className="tw-block">
            <KBIntlRenderReactDom
              intlKey={'register.form.desc'}
              placeholders={{
                terms: (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={TERMS_AND_PRIVACY['terms']}
                    className="tw-text-[var(--wox-color-text-description)] tw-underline tw-underline-offset-1"
                  >
                    {t('login.authorizationStatement')}
                  </a>
                ),
                privacy: (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={TERMS_AND_PRIVACY['privacy']}
                    className="tw-text-[var(--wox-color-text-description)] tw-underline tw-underline-offset-1"
                  >
                    {t('login.privacyProtection')}
                  </a>
                ),
              }}
            />
          </KBText>
        </KBForm>
      </div>
    </KBFlex>
  )
}

export default RegisterForm
