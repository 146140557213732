import { useAuthResetPassword } from '@/api'
import {
  KBButton,
  KBFlex,
  KBForm,
  KBInput,
  KBTypography,
} from '@/components/atoms'
import { Rule } from 'antd/es/form'

import { useTranslation } from 'react-i18next'

const { Text } = KBTypography

const RegisterPassword: React.FC = () => {
  const { t } = useTranslation()
  const [form] = KBForm.useForm()
  const location = useLocation()
  const { userToken } = location.state
  const { mutate: setPassword, isPending } = useAuthResetPassword()
  const navigate = useNavigate()

  const validateConfirmPassword = (rule: Rule, value: string) => {
    if (!value || value === form.getFieldValue('password')) {
      return Promise.resolve()
    }
    return Promise.reject(t('login.passwordNotMatch'))
  }

  const onSubmit = () => {
    form.validateFields().then((values) => {
      setPassword(
        {
          data: {
            password: values.password,
            hash: userToken,
            is_register: true,
          },
        },
        {
          onSuccess: () => {
            navigate('/login')
          },
        }
      )
    })
  }
  return (
    <KBFlex
      gap={32}
      vertical
      align="center"
      justify="center"
      className="tw-h-full tw-w-full"
    >
      <div className="tw-w-[476px] tw-text-center">
        <Text className="tw-mb-2 tw-block tw-text-3xl tw-font-bold">
          {t('register.createPassword')}
        </Text>
        <Text className="tw-block tw-text-base" type="secondary">
          {t('register.createPasswordTips')}
        </Text>
      </div>
      <KBForm form={form} layout="vertical" className="tw-w-[350px]">
        <KBForm.Item
          name="password"
          label={t('login.password')}
          rules={[
            {
              required: true,
              message: t('login.passwordDesc'),
            },
          ]}
          className="tw-mb-6"
        >
          <KBInput.Password
            size="large"
            placeholder={t('login.passwordDesc')}
          />
        </KBForm.Item>
        <KBForm.Item
          name="confirmPassword"
          label={t('login.confirmPassword')}
          rules={[
            {
              required: true,
              message: t('login.confirmPasswordDesc'),
            },
            { validator: validateConfirmPassword },
          ]}
        >
          <KBInput.Password
            size="large"
            placeholder={t('login.confirmPasswordDesc')}
          />
        </KBForm.Item>

        <KBForm.Item className="tw-mb-0 tw-mt-3">
          <KBButton
            type="primary"
            size="large"
            onClick={onSubmit}
            className="tw-w-full"
            loading={isPending}
          >
            {t('login.save')}
          </KBButton>
        </KBForm.Item>
      </KBForm>
    </KBFlex>
  )
}

export default RegisterPassword
