import {
  LoginResponseDto,
  useAuthMe,
  useRoleAssignmentFindRolesByUser,
} from '@/api'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import useTeams from '@/hooks/useTeams'
import {
  currentRefreshTokenAtom,
  currentRolesAtom,
  currentSessionTokenAtom,
  currentSpaceAtom,
  currentUserDataAtom,
  currentUserTokenAtom,
  localeAtom,
} from '@/store'
import { setCurrentUserToken } from '@/utils/axios'
import { isH5, isTeams } from '@/utils/userEnvironment'
import { formatStorage } from '@wox/dayjs'
import { useAtom, useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const AuthenticatedLayout: React.FC = () => {
  const setUserToken = useSetAtom(currentUserTokenAtom)
  const setRefreshToken = useSetAtom(currentRefreshTokenAtom)
  const setSessionToken = useSetAtom(currentSessionTokenAtom)
  const setCurrentSpace = useSetAtom(currentSpaceAtom)
  const setCurrentUserData = useSetAtom(currentUserDataAtom)
  const setCurrentUserRoles = useSetAtom(currentRolesAtom)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const [locale, setLocale] = useAtom(localeAtom)

  const {
    data: _userRoles,
    isLoading: isLoadingRoles,
    refetch: fetchRolesByUser,
  } = useRoleAssignmentFindRolesByUser({
    query: {
      enabled: false,
    },
  })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const from = searchParams.get('from')
  const {
    mutate: fetchMe,
    error,
    isPending,
  } = useAuthMe({
    // @ts-ignore
    client: {
      NO_NOTIFICATION: from === 'teams' ? true : undefined,
    },
  })

  const loginSuccessCallback = async (data: LoginResponseDto) => {
    // console.log(data)
    if (!data.user) {
      setIsAuthenticated(false)
      const redirectUri = window.location.href
      navigate(
        `/login?error=invalid_user&redirect_uri=${encodeURIComponent(
          redirectUri
        )}`
      )
      return
    }
    if (locale !== data?.user?.language) {
      // 如果在teams就取teams的语言（该逻辑在useTeams中） 否则取用户自己的语言
      !isTeams() && setLocale(data?.user?.language || 'en-US')
    }
    setUserToken(data.token)
    setRefreshToken(data.refreshToken)
    setSessionToken(data.sessionToken)
    setCurrentSpace(data?.user?.space)
    setCurrentUserData(data?.user || {})
    formatStorage.set({
      ...formatStorage.get(),
      dateFormat: data?.user?.date_format || 'YYYY-MM-DD',
    })

    setCurrentUserToken(data.token)
    // fetch user roles and store them
    const { data: userRoles } = await fetchRolesByUser()
    // console.log(`userRoles`, userRoles)

    setCurrentUserRoles(userRoles || [])
    setIsAuthenticated(true)
  }

  // @ts-ignore
  const loginErrorCallback = (error) => {
    setIsAuthenticated(false)
    if (from === 'teams') {
      navigate(`/no-register`)
      return
    }
    const redirectUri = window.location.href
    navigate(
      `/login?error=invalid_token&description=${encodeURIComponent(
        error?.message || ''
      )}&redirect_uri=${encodeURIComponent(redirectUri)}`
    )
  }

  const {
    initTeams,
    loginWithTeams,
    loading: teamLoading,
  } = useTeams({
    onLoginSuccess: loginSuccessCallback,
    onLoginError: loginErrorCallback,
  })

  const getUserData = async () => {
    if (from === 'teams') {
      sessionStorage.setItem('inTeams', 'true')
      await initTeams()
      if (isH5) {
        loginWithTeams()
        return
      }
    }

    fetchMe(
      {},
      {
        onSuccess: loginSuccessCallback,
        onError(error) {
          console.log('fetchMe error', error)
          if (from === 'teams') {
            // teams内 授权失效后，尝试使用teams token登录
            loginWithTeams()
          } else {
            loginErrorCallback(error)
          }
        },
      }
    )
  }

  // fetch user data and roles every time the component is mounted
  useEffect(() => {
    getUserData()
  }, [])

  if (isPending || isLoadingRoles || teamLoading) {
    return <KBSuspenseLoading fullScreen={true} />
  }

  return isAuthenticated ? <Outlet /> : null
}

export default AuthenticatedLayout
