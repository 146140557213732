// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthLoginByTeamsTokenMutationRequest, AuthLoginByTeamsTokenMutationResponse, AuthLoginByTeamsToken401 } from "../models/AuthLoginByTeamsToken";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthLoginByTeamsTokenClient = typeof client<AuthLoginByTeamsTokenMutationResponse, AuthLoginByTeamsToken401, AuthLoginByTeamsTokenMutationRequest>;
type AuthLoginByTeamsToken = {
    data: AuthLoginByTeamsTokenMutationResponse;
    error: AuthLoginByTeamsToken401;
    request: AuthLoginByTeamsTokenMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthLoginByTeamsTokenMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthLoginByTeamsTokenClient>[0]>;
        return: Awaited<ReturnType<AuthLoginByTeamsTokenClient>>;
    };
};
/**
 * @summary 使用teams token登录
 * @link /api/v5/auth/login-by-teams-token
 */
export function useAuthLoginByTeamsToken(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthLoginByTeamsToken["response"], AuthLoginByTeamsToken["error"], {
        data?: AuthLoginByTeamsToken["request"];
    }>;
    client?: AuthLoginByTeamsToken["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthLoginByTeamsToken["data"], AuthLoginByTeamsToken["error"], AuthLoginByTeamsToken["request"]>({
                method: "post",
                url: `/api/v5/auth/login-by-teams-token`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}