import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'

import {
  KBDivider,
  KBFlex,
  KBLayout,
  KBTooltip,
  KBTypography,
} from '@/components/atoms'
import { cn } from '@/utils/tailwind.helper'
const { Text, Link } = KBTypography
const { Sider, Content } = KBLayout

import { motion } from 'framer-motion'
import { useMemo, useState } from 'react'

import {
  SpaceFeatureEntity,
  useEmployeeWorkStatusFindMany,
  useLocationFindById,
  useSpaceFeatureFindMany,
} from '@/api'
import Init from '@/components/layouts/Init'
import UserLayoutAvatar from '@/components/layouts/components/UserLayoutAvatar'
import { KBResourceReservationFormModal } from '@/components/molecules/KBResourceReservationForm'
import BatchSelectResourceModal from '@/components/molecules/KBResourceReservationForm/components/BatchSelectResourceModal'
import { KBAppVersion } from '@/components/organisms/KBAppVersion'
import KBReservationSignModal from '@/components/organisms/KBReservationSignModal/KBReservationSignModal'
import KBApprovalModal from '@/components/organisms/KBResourceReservation/KBApprovalModal'
import ShareModal from '@/components/organisms/KBScheduler/BigScheduler/ShareModal'
import EditReservationResourceModal from '@/components/organisms/KBScheduler/BigScheduler/components/EditReservationResourceModal'
import ExtendMeetingModal from '@/components/organisms/KBScheduler/BigScheduler/components/ExtendMeetingModal'
import SchedulerReservationNoticeModal from '@/components/organisms/KBScheduler/BigScheduler/components/SchedulerReservationNoticeModal'
import { FeatureNameEnum } from '@/enums/modelEnums'
import { currentUserDataAtom, hasAdminRoleAtom, locationAtom } from '@/store'
import { MAX_PAGINATION_LIMIT } from '@/utils/constants'
import { isTeams } from '@/utils/userEnvironment'
import dayjs from '@wox/dayjs'
import LogoWhite from '~icons/wox-icons/logoWhite'

const UserLayout: React.FC = () => {
  const { t } = useTranslation()
  const [hasAdminRole] = useAtom(hasAdminRoleAtom)
  const [currentUser] = useAtom(currentUserDataAtom)
  const [locationData, setLocationData] = useAtom(locationAtom)
  const { data: spaceFeatures } = useSpaceFeatureFindMany({
    limit: MAX_PAGINATION_LIMIT,
  })

  // 如果用户有混合办公状态，则使用混合办公状态中的location_id，否则使用用户当前的location_id
  const { data: workStatus, isLoading: isLoadingWorkStatus } =
    useEmployeeWorkStatusFindMany({
      limit: 1,
      user_id: currentUser?.id,
      date: dayjs().format('YYYY-MM-DD'),
    })

  const defaultLocationId =
    workStatus?.result[0]?.location_id || currentUser?.location_id

  const { data: locationQueryData, isLoading: isLoadingLocation } =
    useLocationFindById(defaultLocationId!, {
      query: {
        enabled: !!defaultLocationId,
      },
    })

  useEffect(() => {
    // set default location
    setLocationData(locationQueryData)
  }, [locationQueryData])

  const navigate = useNavigate()

  const location = useLocation()

  const path = location.pathname.split('/')?.[2]
  const [selectedMenu, setSelectedMenu] = useState(path)

  const menuItems = [
    {
      key: 'dashboard',
      path: '/user/dashboard',
      icon: '/useLayoutMenu/dashboard.svg',
      whileIcon: '/useLayoutMenu/dashboardWhite.svg',
      features: [FeatureNameEnum.Dashboard],
      name: t('menu.location.dashboard'),
    },
    {
      key: 'hybrid-work',
      path: '/user/hybrid-work',
      icon: '/useLayoutMenu/WorkStatus.svg',
      whileIcon: '/useLayoutMenu/WorkStatusWhite.svg',
      features: [FeatureNameEnum.WorkStatus],
      name: t('common.hybridWork'),
    },
    {
      key: 'reservations',
      path: '/user/reservations',
      icon: '/useLayoutMenu/calendar.svg',
      whileIcon: '/useLayoutMenu/calendarWhite.svg',
      features: [FeatureNameEnum.Resource],
      name: t('reservation.reservations'),
    },
    {
      key: 'tasks',
      // path: '/user/tasks',
      path: '/user/tasks/pending',
      // features: [FeatureNameEnum.Task],
      icon: '/useLayoutMenu/clipboard.svg',
      whileIcon: '/useLayoutMenu/clipboardWhite.svg',
      features: [FeatureNameEnum.Task],
      name: t('menu.tasks'),
    },
    {
      key: 'deliveries',
      // path: '/user/deliveries',
      path: '/user/deliveries/pending',
      // features: [FeatureNameEnum.Delivery],
      icon: '/useLayoutMenu/box.svg',
      whileIcon: '/useLayoutMenu/boxWhite.svg',
      features: [FeatureNameEnum.Delivery],
      name: t('menu.delivery'),
    },
    {
      key: 'visitors',
      path: '/user/visitors',
      icon: '/useLayoutMenu/contacts.svg',
      whileIcon: '/useLayoutMenu/contactsWhite.svg',
      features: [FeatureNameEnum.Visitor],
      name: t('model.visitor'),
    },
    {
      key: 'users',
      path: '/user/users',
      icon: '/useLayoutMenu/notebook.svg',
      whileIcon: '/useLayoutMenu/notebookWhite.svg',
      features: [FeatureNameEnum.AddressBook],
      name: t('addressBook'),
    },
  ]

  const filteredMenuItems = useMemo(() => {
    const features = spaceFeatures?.result as SpaceFeatureEntity[]
    if (!features) return null
    const enabledFeatures = features
      ?.filter((feature) => feature.is_enabled)
      .map((feature) => feature.feature.name as FeatureNameEnum)

    return menuItems.filter(
      (menuItem) =>
        !menuItem.features || // 如果菜单项没有features，则默认显示
        menuItem.features?.some((feature) => enabledFeatures.includes(feature))
    )
  }, [spaceFeatures, menuItems])

  useEffect(() => {
    if (!path) {
      const firstMenu = filteredMenuItems?.[0]
      firstMenu && setSelectedMenu(firstMenu.key)
      firstMenu && navigate(firstMenu?.path)
    } else {
      setSelectedMenu(path)
    }
  }, [path, filteredMenuItems])

  return (
    <KBLayout>
      <KBApprovalModal id="KBApprovalModal" />
      <BatchSelectResourceModal id="batchSelectResourceModal" />
      {/* 延长会议modal */}
      <ExtendMeetingModal id="ExtendMeetingModal" />
      {/* 分享预定modal */}
      <ShareModal id="ShareModal" />
      {/* 预定签到、签退modal */}
      <KBReservationSignModal id="KBResourcesSignModal" />
      {/* @ts-ignore */}
      <KBResourceReservationFormModal id="KBResourceReservationFormModal" />
      {/* @ts-ignore */}
      <EditReservationResourceModal id="EditReservationResourceModal" />
      <SchedulerReservationNoticeModal id="SchedulerReservationNoticeModal" />
      {/* <UserLayoutHeader /> */}
      <Init />

      {isTeams() ? (
        <Outlet />
      ) : (
        <KBLayout className="tw-h-screen">
          <Sider
            width="70"
            className="tw-relative tw-flex-row tw-bg-[var(--wox-color-primary)] tw-px-1 tw-pb-6 tw-pt-2"
            trigger={
              <>
                <KBDivider />
                <KBAppVersion className=" tw-text-white" />
              </>
            }
          >
            <KBFlex
              className="tw-h-full tw-w-full"
              vertical
              justify="space-between"
            >
              <div>
                <div className="tw-my-4 tw-w-full tw-text-center">
                  <LogoWhite style={{ width: '28.85px' }} />
                </div>
                {filteredMenuItems?.map((menu) => {
                  return (
                    <KBFlex
                      key={menu.key}
                      data-testid={menu.key}
                      className="tw-mb-1 tw-h-12 tw-cursor-pointer tw-rounded-lg  tw-leading-none "
                      vertical
                      align="center"
                      justify="center"
                      onClick={() => {
                        setSelectedMenu(menu.key)
                        navigate(menu.path)
                      }}
                    >
                      <KBTooltip title={menu.name} placement="left">
                        <KBFlex
                          align="center"
                          justify="center"
                          className={cn(
                            'tw-h-10 tw-w-10 tw-rounded-md tw-font-medium tw-text-[var(--wox-color-text)] hover:tw-bg-[rgba(255,255,255,0.2)]',
                            menu.key === selectedMenu &&
                              'tw-bg-[rgba(255,255,255,0.2)]'
                          )}
                        >
                          <motion.span whileHover={{ scale: 1.2 }}>
                            <img
                              className="tw-h-6 tw-w-6"
                              aria-label={menu.name}
                              src={
                                menu.key === selectedMenu
                                  ? menu.whileIcon
                                  : menu.icon
                              }
                            />
                          </motion.span>
                        </KBFlex>
                      </KBTooltip>
                    </KBFlex>
                  )
                })}
              </div>
              <KBFlex vertical gap={16} align="center" className="tw-w-full">
                {/* <KBTooltip title={t('menu.helpDocument')} placement="left">
                <KBButton
                  type="text"
                  icon={
                    <KBIcon
                      name="life-buoy"
                      size={18}
                      className="tw-text-white "
                    />
                  }
                  shape="circle"
                  className="tw-px-2 hover:tw-bg-[rgba(255,255,255,0.1)]"
                  onClick={() => {
                    window.open(USER_HELP_URL!, '_blank')
                  }}
                />
              </KBTooltip> */}
                {/* 
              {hasAdminRole && (
                <KBTooltip title={t('menu.changeAdminSwitch')} placement="left">
                  <KBButton
                    type="text"
                    icon={
                      <KBIcon
                        name="user-cog"
                        size={18}
                        className="tw-text-white"
                      />
                    }
                    shape="circle"
                    className="tw-px-2 hover:tw-bg-[rgba(255,255,255,0.1)]"
                    onClick={() => {
                      navigate('/admin/dashboard')
                      // theme === 'light' ? setTheme('dark') : setTheme('light')
                    }}
                  />
                </KBTooltip>
              )} */}
                <UserLayoutAvatar />
                {/* <KBAppVersion className="tw-text-white" /> */}
              </KBFlex>
            </KBFlex>
          </Sider>
          {!isLoadingWorkStatus && !isLoadingLocation && locationData && (
            <Outlet />
          )}
        </KBLayout>
      )}
    </KBLayout>
  )
}

export default UserLayout
