/**
 *
 * 翻译 国际化传入ReactDom
 */

import React from 'react'
import { useTranslation } from 'react-i18next'
export interface KBIntlRenderReactDomProps {
  intlKey: string
  placeholders: Record<string, React.ReactNode>
}
export const KBIntlRenderReactDom: React.FC<KBIntlRenderReactDomProps> = (
  props
) => {
  const { t } = useTranslation()
  const { intlKey, placeholders } = props
  const valueString = t(intlKey)
  // FIXME: why t(intlKey).d is not working?
  const template: string = t(intlKey) //.replace(`${valueString}`, '')

  let parts: (string | React.ReactNode)[] = [template]
  Object.keys(placeholders).forEach((placeholder) => {
    parts = parts.flatMap((part) =>
      typeof part === 'string' ? part.split(`{${placeholder}}`) : [part]
    )
  })

  const result = parts.reduce<React.ReactNode[]>((acc, part, index) => {
    const placeholderKey = Object.keys(placeholders)[index - 1]
    return index === 0
      ? [...acc, part]
      : [...acc, placeholders[placeholderKey], part]
  }, [])

  return (
    <span>
      {result.map((part, index) => (
        <span key={index}>{part}</span>
      ))}
    </span>
  )
}
