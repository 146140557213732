import { RouteObject } from 'react-router-dom'

import AuthLayout from '@/components/layouts/AuthLayout'
import FreeRegistrationLayout from '@/components/layouts/FreeRegistrationLayout'
import AppAuthorizationResult from '@/pages/auth/AppAuthorizationResult'
import AuthFailure from '@/pages/auth/AuthFailure'
import AuthFirstLogin from '@/pages/auth/AuthFirstLogin'
import AuthSuccess from '@/pages/auth/AuthSuccess'
import Login from '@/pages/auth/Login'
import NoRegister from '@/pages/auth/NoRegister'
import ResetPassword from '@/pages/auth/ResetPassword'
import ResetPasswordSuccess from '@/pages/auth/ResetPasswordSuccess'
import Signup from '@/pages/auth/Signup'

import RegisterForm from '@/pages/freeRegistration/RegisterForm'
import RegisterPassword from '@/pages/freeRegistration/RegisterPassword'
import RegisterVerification from '@/pages/freeRegistration/RegisterVerification'

export const authRoutes: RouteObject[] = [
  {
    path: 'login',
    element: <AuthLayout />,
    children: [{ path: '', element: <Login /> }],
  },
  {
    path: 'signup',
    element: <AuthLayout />,
    children: [{ path: '', element: <Signup /> }],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      { path: 'success', element: <AuthSuccess /> },
      { path: 'failure', element: <AuthFailure /> },
      { path: 'result', element: <AppAuthorizationResult /> },
      { path: 'first-login', element: <AuthFirstLogin /> },
    ],
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
  },
  {
    path: 'reset-password-success',
    element: <ResetPasswordSuccess />,
  },
  {
    path: 'no-register',
    element: <NoRegister />,
  },
  {
    path: 'register',
    element: <FreeRegistrationLayout />,
    children: [
      { path: '', element: <Navigate to="/register/form" replace /> },
      { path: 'form', element: <RegisterForm /> },
      { path: 'verification', element: <RegisterVerification /> },
      { path: 'password', element: <RegisterPassword /> },
    ],
  },
]
